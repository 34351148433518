
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ExpansionPanel from '@/modules/common/components/ui-kit/expansion-panel.vue';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import CustomDropdown from '@/modules/common/components/ui-kit/custom-dropdown.vue';
import type UserService from '@/modules/user/user.service';
import type { MergeGroupParams, RoomsGroup, UnflagGroupParams } from '@/modules/rooms-type-manager/types';
import ArchivedRoomsModel from '@/modules/rooms-type-manager/models/archived-rooms.model';

const HASH_SEPARATOR = '$$$';
const ARCHIVED_ROOM = -2;

export interface UpdateRMSPayload {
    roomTypeId: number;
    toRemove: boolean;
    entireCompset: boolean,
    hotelId: number;
}

@Component({
    components: {
        ExpansionPanel,
        ProviderCard,
        CustomDropdown,
    },
})
export default class RoomMappingGroup extends Vue {
    @inject(RoomsTypeManagerServiceS) private roomsTypeManagerService!: RoomsTypeManagerService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.UserService) private userService!: UserService;

    @Prop({ type: Object, required: true })
    hotelData!: { name: string; id: number; };

    @Prop({ type: Boolean, default: true })
    archivedOnly!: boolean;

    @Prop({ type: Array, default: () => [] })
    providers!: { name: string, value: string }[];

    @Prop({ type: Array, default: null })
    rmsState!: number[];

    @Prop({ type: Boolean, default: false })
    isPending!: boolean;

    @Prop({ type: Boolean, default: false })
    expanded!: boolean;

    selectedRooms: string[] = [];
    foldedRoomTypes: number[] = [];

    @Watch('archivedOnly')
    resetSelected(e?: Event) {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        this.selectedRooms = [];
    }

    mounted() {
        // NOTE For insights, scroll to room saved in sessionStorage, wipe this room from storage after it.
        this.moveToSelectedRoom();
    }

    get archivedRooms() {
        return this.roomsTypeManagerService.archivedRooms;
    }

    get haveSelectedRooms() {
        return !!this.selectedRooms.length;
    }

    get mainHotelId() {
        return +this.$route.params.hotelId;
    }

    get isArchiveRoomsLoading() {
        return this.roomsTypeManagerService.isArchiveLoading;
    }

    get isAdmin() {
        return this.userService.isAdmin;
    }

    get actualProviders() {
        if (this.archivedOnly) {
            return this.providers.filter(item => this.roomsTypeManagerService
                .isArchivedGroupsProviderHasRooms(item.value, this.hotelData.id));
        }

        return this.providers
            .filter(item => this.roomsTypeManagerService
                .isAllGroupsProviderHasRooms(item.value, this.hotelData.id));
    }

    get roomTypes(): RoomTypeModel[] | null {
        if (this.archivedOnly) {
            return [{
                id: ARCHIVED_ROOM,
                name: this.isArchiveRoomsLoading ? this.$tc('loading') : this.$tc('archived'),
                displayName: this.$tc('archived'),
            }];
        }
        return this.roomTypesService.realRooms;
    }

    toggleSelect(provider: string, roomTypeId: number, group: RoomsGroup, el?: HTMLElement) {
        if (el?.classList.contains('rooms-list-icon')) {
            return;
        }

        const hash = [provider, roomTypeId, JSON.stringify(group)].join(HASH_SEPARATOR);

        this.selectedRooms = this.selectedRooms.includes(hash)
            ? this.selectedRooms.filter(ref => ref !== hash)
            : [...this.selectedRooms, hash];
    }

    isSelected(provider: string, roomTypeId: number, group: RoomsGroup) {
        const hash = [provider, roomTypeId, JSON.stringify(group)].join(HASH_SEPARATOR);

        return this.selectedRooms.includes(hash);
    }

    isFoldedRoomType(roomTypeId: number) {
        return this.foldedRoomTypes.includes(roomTypeId);
    }

    isRoomsInSameProvider(provider: string) {
        return !this.selectedRooms.find(r => r.split(HASH_SEPARATOR)[0] !== provider);
    }

    toggleRoomTypeFold(roomTypeId: number) {
        if (!this.isFoldedRoomType(roomTypeId)) {
            this.foldedRoomTypes.push(roomTypeId);
        } else {
            const indexToRemove = this.foldedRoomTypes.indexOf(roomTypeId);
            this.foldedRoomTypes.splice(indexToRemove, 1);
        }
    }

    getRoomGroups(provider: string, roomTypeId?: number) {
        if (roomTypeId === ARCHIVED_ROOM) {
            return this.roomsTypeManagerService.getArchivedRoomGroups(this.hotelData.id, provider);
        }

        return this.roomsTypeManagerService.getRoomGroups(this.hotelData.id, provider, roomTypeId);
    }

    getMaxRoomsCount(provider: string) {
        const roomTypes = this.roomTypes || [];
        const counts = roomTypes.map(roomType => this.getRoomGroups(provider, roomType.id).length);

        return Math.max(...counts);
    }

    getGroupMessage(group: RoomsGroup) {
        if (group.isNew) {
            return this.$tc('settings.roomMapping.newRoomDetected', 0, [group.name]);
        }

        if (group.isAutoArchived) {
            return this.$tc('settings.roomMapping.autoArchivedRoom', 0, [group.name]);
        }

        return group.name;
    }

    // For room insight, to get specified room from sessionStorage and scroll to it
    // [TODO] grouping rooms breaks this, change rooms with group IDs
    moveToSelectedRoom() {
        if (!this.expanded) {
            return;
        }

        const rawRooms = sessionStorage.getItem('rooms');

        if (!rawRooms) {
            return;
        }

        const rooms = JSON.parse(rawRooms);
        sessionStorage.removeItem('rooms');

        const { source, roomTypeIds, roomNames } = rooms;
        let elRef = null;

        for (let i = 0; i < roomNames.length; i += 1) {
            const allGroups = this.getRoomGroups(source, roomTypeIds[i]);
            const group = allGroups.find(g => g.name === roomNames[i]);

            if (group) {
                if (
                    // FIXME Move the second condition into the const
                    !elRef || (elRef as HTMLElement[])[0].getBoundingClientRect().y
                        > (this.$refs[group.groupId] as HTMLElement[])[0].getBoundingClientRect().y
                ) {
                    elRef = this.$refs[group.groupId];
                }
                this.toggleSelect(source, roomTypeIds[i], group);
            }
        }

        const { panel } = this.$refs;

        if (!elRef || !panel) {
            return;
        }

        const panelEl = (panel as Vue).$el;
        const panelRect = panelEl.getBoundingClientRect();
        const elRect = (elRef as HTMLElement[])[0].getBoundingClientRect();
        panelEl.scrollTo(elRect.x - panelRect.x - elRect.width * 1.2, elRect.y - panelRect.y - elRect.height * 1.9);
        this.$emit('init-scroll', panelRect.y);
    }

    async moveSelectedRooms(targetRoomTypeId: number) {
        const { id: hotelId } = this.hotelData;

        this.selectedRooms.forEach((hash: string) => {
            const [provider, roomTypeId, group] = hash.split(HASH_SEPARATOR);
            const { groupId } = JSON.parse(group) as RoomsGroup;

            this.roomsTypeManagerService
                .moveRoom(hotelId, +roomTypeId, provider, groupId, targetRoomTypeId);
        });

        this.$emit('set-pending', true);

        try {
            await this.roomsTypeManagerService.saveDocument(this.mainHotelId);
        } finally {
            this.$emit('set-pending', false);
        }

        this.reset();
    }

    getDropdownItemsForRoom(roomTypeId: number) {
        const isAvoidedRMS = this.rmsState.includes(roomTypeId);

        const items = [
            {
                text: this.$tc('rename'),
                action: () => this.renameRoom(roomTypeId),
            },
            {
                text: isAvoidedRMS ? this.$tc('settings.roomMapping.restoreRms') : this.$tc('settings.roomMapping.avoidRms'),
                children: [
                    {
                        text: this.$tc('settings.roomMapping.forHotel'),
                        action: () => this.toggleAvoidRMS(roomTypeId),
                    },
                    {
                        text: this.$tc('settings.roomMapping.forAll'),
                        action: () => this.toggleAvoidRMS(roomTypeId, true),
                    },
                ],
            },
        ];

        if (roomTypeId !== 2) {
            items.push({
                text: this.$tc('delete'),
                action: () => this.deleteRoom(roomTypeId),
            });
        }

        return items;
    }

    async handleMergeGroups(e: MouseEvent, provider: string, targetGroup: RoomsGroup) {
        e.stopPropagation();
        const groupsToMerge = this.selectedRooms.map(g => JSON.parse(g.split(HASH_SEPARATOR)[2]));

        const mergedRooms = [
            ...targetGroup.rooms,
            ...groupsToMerge.reduce((acc, g) => ([
                ...acc,
                ...g.rooms,
            ]), [] as RoomsGroup[]),
        ];

        const mergeParams = {
            groupId: targetGroup.groupId,
            provider,
            mainHotelId: this.mainHotelId,
            hotelId: this.hotelData.id,
            rooms: mergedRooms,
        } as MergeGroupParams;

        this.$emit('set-pending', true);
        try {
            await this.roomsTypeManagerService.updateRoomGroups(mergeParams);
        } finally {
            this.$emit('set-pending', false);
            this.reset();
        }
    }

    async handleSplitRoomFromGroup(rooms: string[], provider: string, groupId: string) {
        const mergeParams = {
            groupId,
            provider,
            mainHotelId: this.mainHotelId,
            hotelId: this.hotelData.id,
            rooms,
        } as MergeGroupParams;

        this.$emit('set-pending', true);
        try {
            await this.roomsTypeManagerService.updateRoomGroups(mergeParams);
        } finally {
            this.$emit('set-pending', false);
            this.reset();
        }
    }

    async handleUnflagNewGroup(e: MouseEvent, provider: string, groupId: string) {
        e.stopPropagation();

        const unflagParams = {
            groupId,
            provider,
            mainHotelId: this.mainHotelId,
            hotelId: this.hotelData.id,
        } as UnflagGroupParams;

        this.$emit('set-pending', true);
        try {
            await this.roomsTypeManagerService.unflagNewGroup(unflagParams);
        } finally {
            this.$emit('set-pending', false);
        }
    }

    private async toggleAvoidRMS(roomTypeId: number, entireCompset = false) {
        const { id: hotelId } = this.hotelData;
        const toRemove = this.rmsState.includes(roomTypeId);

        this.$emit('update-rms', {
            hotelId,
            roomTypeId,
            toRemove,
            entireCompset,
        });
    }

    async archiveSelectedRooms(e: Event) {
        e.stopPropagation();

        // NOTE Transform selected rooms array to ArchiveRoomsModel
        const groups = this.selectedRooms.reduce((acc, hash) => {
            const [provider, _, group] = hash.split(HASH_SEPARATOR);
            const { groupId, name, rooms } = JSON.parse(group) as RoomsGroup;
            if (!acc.providers[provider]) {
                acc.providers[provider] = {};
            }

            if (!acc.providers[provider][this.hotelData.id]) {
                acc.providers[provider][this.hotelData.id] = [];
            }

            acc.providers[provider][this.hotelData.id] = [
                ...acc.providers[provider][this.hotelData.id], {
                    groupId,
                    name,
                    rooms,
                    isNew: false,
                    isAutoArchived: false,
                },
            ];

            return acc;
        }, { providers: {} } as ArchivedRoomsModel);

        try {
            this.$emit('set-pending', true);

            if (this.archivedOnly) {
                await this.roomsTypeManagerService.restoreRoomGroupsFromArchive(this.mainHotelId, groups);
            } else {
                await this.roomsTypeManagerService.addRoomGroupsToArchive(this.mainHotelId, groups);
            }
        } finally {
            this.$emit('set-pending', false);
            this.reset();
        }
    }

    private renameRoom(roomTypeId: number) {
        this.$emit('room-rename', roomTypeId);
    }

    private deleteRoom(roomTypeId: number) {
        this.$emit('room-delete', roomTypeId);
    }

    reset() {
        this.selectedRooms = [];
    }
}
